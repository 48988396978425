/* src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Slick Carousel styles */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

html {
  scroll-behavior: smooth;
}

/* Fonts and brand colors */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif; /* Replace with brand font */
  background-color: #f5f5f5;
}

.text-brand-primary {
  color: #1E88E5; /* Example brand primary color */
}

.text-brand-secondary {
  color: #8E24AA; /* Example brand secondary color */
}

.bg-brand-primary {
  background-color: #1E88E5;
}

.bg-brand-secondary {
  background-color: #8E24AA;
}

button {
  border-radius: 25px;
}

a {
  color: #1E88E5;
  text-decoration: none;
}

a:hover {
  color: #8E24AA;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

/* Custom styles for Slick dots */
.slick-dots li button:before {
  color: #5A4DD4; /* Unselected dots color */
  opacity: 0.5; /* Adjust the transparency if needed */
}

.slick-dots li.slick-active button:before {
  color: #5A4DD4; /* Selected dot color */
  opacity: 1; /* Make the active dot fully opaque */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
